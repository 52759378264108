import { faMoon, faSunBright } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect } from "react";

import type { ThemeContextType } from "~/lib/theme-provider";
import { Theme } from "~/lib/theme-provider";

type Props = Readonly<{
  ctx?: ThemeContextType;
  mobile?: boolean;
}>;

export function ThemeToggle({ ctx }: Props): JSX.Element {
  const [theme, setTheme] = ctx ?? [null, null];

  const handleThemeChange = useCallback(() => {
    if (setTheme == null) return;

    const newTheme = theme === Theme.Dark ? Theme.Light : Theme.Dark;
    setTheme(newTheme);

    document.dispatchEvent(
      new CustomEvent("dark-mode-toggled", {
        detail: { enabled: newTheme === Theme.Dark },
      })
    );
  }, [theme, setTheme]);

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key === "l") {
        handleThemeChange();
      }
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleThemeChange]);

  return (
    <button
      aria-label="Toggle color theme"
      id="dark-mode-toggle"
      onClick={handleThemeChange}
      title="Toggle color theme"
      type="button"
      className="group relative flex items-center md:hidden lg:flex"
    >
      <span className="sr-only">Toggle color theme</span>
      <Icons />
      <div className="absolute opacity-0 right-[24px] group-hover:opacity-100 group-hover:translate-x-[-10px] transition-all duration-500 z-50">
        <div className="bg-secondary px-3 py-1.5 text-sm text-primary rounded-md border border-primary">
          L
        </div>
      </div>
    </button>
  );
}

function Icons() {
  return (
    <div className="ml-auto flex items-center justify-center">
      <FontAwesomeIcon
        className="header-icon hidden dark:inline-block global-hover-transition"
        icon={faSunBright}
      />
      <FontAwesomeIcon
        className="header-icon dark:hidden global-hover-transition"
        icon={faMoon}
      />
    </div>
  );
}
