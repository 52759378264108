import type { SVGProps } from "react";

export function DayZero(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`fill-white ${props.className}`}
      width="137"
      height="25"
      viewBox="0 0 137 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2119 1.97226C16.7285 2.4283 17.1817 2.95452 17.5565 3.53598C18.0392 4.28463 18.3919 5.12486 18.583 6.02486L18.6216 6.09025L18.5339 3.63452C18.5112 2.99842 18.7809 2.34308 19.3876 2.15058C20.3611 1.84171 21.4689 2.07329 22.241 2.84532C22.3788 2.98317 22.3788 3.20668 22.241 3.34454L18.7127 6.87278C18.735 7.10816 18.7464 7.3467 18.7464 7.58791V16.5739C18.7464 20.7095 15.3938 24.0622 11.2581 24.0622C9.48151 24.0622 7.84942 23.4435 6.56553 22.4098C5.97521 21.9345 5.84782 21.1137 6.12928 20.41L6.76515 18.8204L4.26902 21.3165C3.16618 22.4193 1.37811 22.4193 0.275267 21.3165C0.137411 21.1786 0.137411 20.9551 0.275267 20.8172L3.80352 17.289C3.78122 17.0536 3.76981 16.8151 3.76981 16.5739V7.58791C3.76981 3.45224 7.12244 0.0996094 11.2581 0.0996094C13.1575 0.0996094 14.8918 0.806805 16.2119 1.97226ZM15.7511 16.5739L15.7511 6.83908L14.4135 9.96006C13.8131 11.3611 12.9509 12.6347 11.873 13.7125L8.13873 17.4468C7.55113 18.0344 7.35917 18.9568 7.91445 19.5751C8.73698 20.4908 9.93028 21.0668 11.2581 21.0668C13.7395 21.0668 15.7511 19.0553 15.7511 16.5739ZM14.3775 6.71502C14.9651 6.12742 15.1571 5.20495 14.6018 4.58672C13.7792 3.67094 12.5859 3.09493 11.2581 3.09493C8.77671 3.09493 6.76513 5.1065 6.76513 7.58791V8.87285C6.76513 10.4954 6.43555 12.1011 5.79638 13.5925L4.51866 16.5739L14.3775 6.71502Z"
        fill="white"
      />
      <path
        d="M33.3192 20.5052V3.49181H37.4647C38.9664 3.49181 40.2683 3.82728 41.3706 4.49824C42.4889 5.15321 43.3435 6.1197 43.9346 7.3977C44.5417 8.65973 44.8452 10.2013 44.8452 12.0225C44.8452 13.8436 44.5417 15.3852 43.9346 16.6473C43.3435 17.9093 42.4889 18.8678 41.3706 19.5228C40.2683 20.1777 38.9664 20.5052 37.4647 20.5052H33.3192ZM35.8353 18.1329H37.3928C38.9424 18.1329 40.1326 17.6297 40.9633 16.6233C41.8099 15.6009 42.2333 14.0673 42.2333 12.0225C42.2333 9.97767 41.8099 8.44407 40.9633 7.42167C40.1326 6.38329 38.9424 5.8641 37.3928 5.8641H35.8353V18.1329Z"
        fill="white"
      />
      <path
        d="M51.1619 20.7928C50.3632 20.7928 49.6443 20.649 49.0053 20.3615C48.3663 20.0739 47.8551 19.6665 47.4717 19.1394C47.1043 18.6122 46.9206 17.9971 46.9206 17.2942C46.9206 16.2079 47.2481 15.3613 47.903 14.7542C48.574 14.1472 49.5804 13.7158 50.9223 13.4602L55.0439 12.5976C55.0439 11.6071 54.8282 10.8643 54.3969 10.3691C53.9656 9.87384 53.3186 9.62622 52.4559 9.62622C51.6572 9.62622 51.0341 9.80994 50.5868 10.1774C50.1395 10.5448 49.836 11.056 49.6763 11.711L47.1362 11.5432C47.3759 10.3291 47.9589 9.34666 48.8855 8.59583C49.8121 7.84501 51.0022 7.46959 52.4559 7.46959C54.0854 7.46959 55.3234 7.93287 56.1701 8.85942C57.0328 9.78597 57.4641 11.064 57.4641 12.6934V17.7256C57.4641 18.0131 57.528 18.2208 57.6558 18.3486C57.7836 18.4604 57.9673 18.5163 58.2069 18.5163H58.9258V20.5052C58.83 20.5212 58.6702 20.5372 58.4466 20.5532C58.2389 20.5691 58.0312 20.5771 57.8235 20.5771C57.2484 20.5771 56.7612 20.4813 56.3618 20.2896C55.9624 20.0979 55.6589 19.8023 55.4512 19.4029C55.2595 18.9876 55.1477 18.4684 55.1158 17.8454H55.4992C55.4033 18.4045 55.1557 18.9077 54.7563 19.355C54.357 19.8023 53.8458 20.1538 53.2227 20.4094C52.5997 20.665 51.9128 20.7928 51.1619 20.7928ZM51.5214 18.8039C52.2882 18.8039 52.9352 18.6681 53.4624 18.3965C53.9895 18.1249 54.3809 17.7415 54.6365 17.2463C54.9081 16.7511 55.0439 16.168 55.0439 15.4971V14.5146L51.4974 15.2335C50.7306 15.3772 50.1955 15.6009 49.8919 15.9044C49.5884 16.2079 49.4366 16.6073 49.4366 17.1025C49.4366 17.6297 49.6204 18.0451 49.9878 18.3486C50.3552 18.6521 50.8664 18.8039 51.5214 18.8039Z"
        fill="white"
      />
      <path
        d="M61.5044 24.0996V22.0628H63.062C63.5093 22.0628 63.8368 21.9909 64.0444 21.8471C64.2681 21.7193 64.4358 21.5037 64.5477 21.2001L64.979 20.0739H64.1643L59.5155 7.75714H62.1274L65.7458 17.7975L69.1245 7.75714H71.7125L66.6324 21.943C66.3768 22.7098 65.9774 23.2609 65.4343 23.5964C64.9071 23.9319 64.1962 24.0996 63.3016 24.0996H61.5044Z"
        fill="white"
      />
      <path
        d="M81.0964 20.5052V18.109L90.1063 5.8641H81.5277V3.49181H92.9819V5.88806L83.9959 18.1329H93.1736V20.5052H81.0964Z"
        fill="white"
      />
      <path
        d="M101.671 20.7928C100.473 20.7928 99.4264 20.5212 98.5318 19.978C97.6532 19.4349 96.9663 18.6601 96.471 17.6537C95.9918 16.6473 95.7521 15.4731 95.7521 14.1312C95.7521 12.7893 95.9918 11.6231 96.471 10.6327C96.9663 9.6422 97.6452 8.86741 98.5078 8.30828C99.3865 7.74916 100.417 7.46959 101.599 7.46959C102.733 7.46959 103.732 7.74117 104.594 8.28432C105.457 8.8115 106.128 9.5783 106.607 10.5847C107.102 11.5752 107.35 12.7733 107.35 14.1791V14.8501H98.2922C98.3721 16.0642 98.7075 16.9907 99.2986 17.6297C99.9057 18.2527 100.704 18.5643 101.695 18.5643C102.446 18.5643 103.069 18.3885 103.564 18.0371C104.059 17.6697 104.403 17.1984 104.594 16.6233L107.134 16.791C106.767 18.0051 106.104 18.9796 105.145 19.7145C104.203 20.4333 103.045 20.7928 101.671 20.7928ZM98.2922 12.9091H104.738C104.642 11.8228 104.307 11.0161 103.732 10.4889C103.173 9.9617 102.462 9.69811 101.599 9.69811C100.704 9.69811 99.9696 9.96969 99.3945 10.5128C98.8194 11.056 98.4519 11.8547 98.2922 12.9091Z"
        fill="white"
      />
      <path
        d="M113.93 20.5052V7.75714H115.847L116.063 11.0161L115.847 10.9921C116.007 9.88981 116.351 9.07508 116.878 8.54791C117.405 8.02073 118.132 7.75714 119.058 7.75714H121.766V9.86585H119.154C118.547 9.86585 118.036 9.98566 117.621 10.2253C117.205 10.4489 116.886 10.7924 116.662 11.2557C116.454 11.703 116.351 12.2621 116.351 12.9331V20.5052H113.93ZM110.695 20.5052V18.4684H120.328V20.5052H110.695ZM110.695 9.79396V7.75714H115.296V9.79396H110.695Z"
        fill="white"
      />
      <path
        d="M130.24 20.7928C129.057 20.7928 128.019 20.5212 127.124 19.978C126.246 19.4349 125.559 18.6601 125.064 17.6537C124.584 16.6473 124.345 15.4731 124.345 14.1312C124.345 12.7733 124.584 11.5991 125.064 10.6087C125.559 9.60226 126.246 8.82747 127.124 8.28432C128.019 7.74117 129.057 7.46959 130.24 7.46959C131.422 7.46959 132.452 7.74117 133.331 8.28432C134.225 8.82747 134.912 9.60226 135.391 10.6087C135.887 11.5991 136.134 12.7733 136.134 14.1312C136.134 15.4731 135.887 16.6473 135.391 17.6537C134.912 18.6601 134.225 19.4349 133.331 19.978C132.452 20.5212 131.422 20.7928 130.24 20.7928ZM130.24 18.5643C131.31 18.5643 132.133 18.1729 132.708 17.3901C133.299 16.6073 133.594 15.521 133.594 14.1312C133.594 12.7414 133.299 11.6551 132.708 10.8723C132.133 10.0895 131.31 9.69811 130.24 9.69811C129.169 9.69811 128.338 10.0895 127.747 10.8723C127.172 11.6551 126.885 12.7414 126.885 14.1312C126.885 15.521 127.172 16.6073 127.747 17.3901C128.338 18.1729 129.169 18.5643 130.24 18.5643Z"
        fill="white"
      />
    </svg>
  );
}
