import type { SVGProps } from "react";

export function Coreum(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`dark:border dark:border-gray-700 ${props.className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="coreum-icon"
    >
      <defs>
        <linearGradient
          id="gradient"
          x1="10.95"
          y1="16.46"
          x2="10.95"
          y2="3.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#076b45" />
          <stop offset="1" stopColor="#2dd195" />
        </linearGradient>
      </defs>
      <circle className="fill-[#090909]" cx="10" cy="10" r="10" />
      <path
        fill="url(#gradient)"
        d="M7.72,12.3l-2.27,2.27c2.52,2.52,6.59,2.52,9.11,0,2.52-2.52,2.52-6.6,0-9.12-2.52-2.52-6.59-2.52-9.11,0l2.27,2.27c1.26-1.26,3.31-1.26,4.57,0,1.26,1.26,1.26,3.31,0,4.57-1.26,1.26-3.31,1.26-4.57,0ZM10,11.63c.89,0,1.61-.72,1.61-1.61s-.72-1.61-1.61-1.61-1.61.72-1.61,1.61.72,1.61,1.61,1.61Z"
      />
    </svg>
  );
}
